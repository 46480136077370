import { ApolloClient, HttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from 'apollo-link-context';

const httpLink = new HttpLink({
  uri: `${process.env.REACT_APP_BACKEND_URL}/graphql?access_token=${process.env.REACT_APP_DIRECTUS_TOKEN}`,
});

// Middleware to set custom headers
const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      'User-Agent': 'custom-agent',
    },
  };
});

const client = new ApolloClient({
  // link: authLink.concat(httpLink),
  uri: `${process.env.REACT_APP_BACKEND_URL}/graphql?access_token=${process.env.REACT_APP_DIRECTUS_TOKEN}`, // Replace with your Directus GraphQL endpoint
  cache: new InMemoryCache(),
});

export default client;
