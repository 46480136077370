import React from 'react';
import { Link } from 'react-router-dom';
import bg from '../../images/couple/image-bg.svg';
import { buildImageUrl } from '../../utils';
import ReadMore from '../ReadMore/ReadMore';

const CoupleSection2 = ({ data }) => {
  if (!data || !data.site_data?.length) {
    return '';
  }

  const images = data.images[0];
  const site_data = data.site_data[0];
  const socials = data.socials[0];

  return (
    <section className="wpo-couple-section section-padding" id="couple">
      <div className="container">
        <div className="couple-area clearfix">
          <div className="row align-items-center">
            <div className="col col-md-12 col-12">
              <div className="couple-item">
                <div className="couple-img-wrap">
                  <div className="couple-img">
                    <img src={buildImageUrl(images?.her_515_468?.id)} alt="" />
                  </div>
                  <div className="c-shape">
                    <img src={bg} alt="" />
                  </div>
                </div>
                <div className="couple-text">
                  <h3>{site_data.her_name}</h3>
                  <ReadMore characterLimit={200}>
                    {site_data.about_her}
                  </ReadMore>
                  <div className="social">
                    <ul>
                      {socials?.her_facebook && (
                        <li>
                          <Link to={socials?.her_facebook}>
                            <i className="ti-facebook"></i>
                          </Link>
                        </li>
                      )}
                      {socials?.her_twitter && (
                        <li>
                          <Link to={socials?.her_twitter}>
                            <i className="ti-twitter-alt"></i>
                          </Link>
                        </li>
                      )}
                      {socials?.her_instagram && (
                        <li>
                          <Link to={socials?.her_instagram}>
                            <i className="ti-instagram"></i>
                          </Link>
                        </li>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col col-md-12 col-12">
              <div className="couple-item">
                <div className="couple-img-wrap">
                  <div className="couple-img">
                    <img src={buildImageUrl(images?.him_515_468.id)} alt="" />
                  </div>
                  <div className="c-shape">
                    <img src={bg} alt="" />
                  </div>
                </div>
                <div className="couple-text">
                  <h3>{site_data.his_name}</h3>
                  <ReadMore characterLimit={200}>
                    {site_data.about_him}
                  </ReadMore>
                  <div className="social">
                    <ul>
                      {socials?.his_facebook && (
                        <li>
                          <Link to={socials?.his_facebook}>
                            <i className="ti-facebook"></i>
                          </Link>
                        </li>
                      )}
                      {socials?.his_twitter && (
                        <li>
                          <Link to={socials?.his_twitter}>
                            <i className="ti-twitter-alt"></i>
                          </Link>
                        </li>
                      )}
                      {socials?.his_instagram && (
                        <li>
                          <Link to={socials?.his_instagram}>
                            <i className="ti-instagram"></i>
                          </Link>
                        </li>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="shape-1">
        <svg
          viewBox="0 0 1920 692"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <g opacity="0.1">
            <path
              className="stroke-color"
              d="M-11 689C176.333 697 609 669.4 841 495L1111 279C1263.67 154.333 1640.6 -71.0002 1927 24.9998"
              stroke=""
              strokeWidth="2"
            />
            <path
              d="M-11 689C176.333 697 609 669.4 841 495L1111 279C1263.67 154.333 1640.6 -71.0002 1927 24.9998"
              stroke="black"
              strokeOpacity="0.2"
              strokeWidth="2"
            />
          </g>
          <g opacity="0.1">
            <path
              className="stroke-color"
              d="M1927 689C1739.67 697 1307 669.4 1075 495L805 279C652.333 154.333 275.4 -71.0002 -11 24.9998"
              stroke=""
              strokeWidth="2"
            />
            <path
              d="M1927 689C1739.67 697 1307 669.4 1075 495L805 279C652.333 154.333 275.4 -71.0002 -11 24.9998"
              stroke="black"
              strokeOpacity="0.2"
              strokeWidth="2"
            />
          </g>
          <path
            className="fill-color"
            d="M879 397C501.4 54.5998 135 31.6665 -1 62.9998V649C579.8 636.2 827.667 475.667 879 397Z"
            fill=""
          />
          <path
            className="fill-color"
            d="M1041 397C1418.6 54.5998 1785 31.6665 1921 62.9998V649C1340.2 636.2 1092.33 475.667 1041 397Z"
            fill=""
          />
        </svg>
      </div>
    </section>
  );
};

export default CoupleSection2;
