import React from 'react';
import { Link } from 'react-router-dom';
import MobileMenu from '../MobileMenu/MobileMenu';

const Header = (props) => {
  const scrollToSection = (sectionId) => {
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <header id="header" className={props.topbarNone}>
      <div className={`wpo-site-header ${props.hclass}`}>
        <nav className="navigation navbar navbar-expand-lg navbar-light">
          <div className="container-fluid">
            <div className="row align-items-center">
              <div className="col-lg-3 col-md-3 col-3 d-lg-none dl-block">
                <div className="mobail-menu">
                  <MobileMenu />
                </div>
              </div>
              <div className="col-lg-2 col-md-6 col-6">
                <div className="navbar-header">
                  <Link
                    onClick={() => scrollToSection('home')}
                    className="navbar-brand"
                    to="#home">
                    Our Wedding
                  </Link>
                </div>
              </div>
              <div className="col-lg-10 col-md-1 col-1">
                <div
                  id="navbar"
                  className="collapse navbar-collapse navigation-holder">
                  <button className="menu-close">
                    <i className="ti-close"></i>
                  </button>
                  <ul className="nav navbar-nav mb-2 mb-lg-0">
                    <li>
                      <a
                        onClick={() => scrollToSection('home')}
                        href="javascript:void(0);">
                        Home
                      </a>
                    </li>
                    <li>
                      <a
                        onClick={() => scrollToSection('couple')}
                        href="javascript:void(0);">
                        Couple
                      </a>
                    </li>
                    <li>
                      <a
                        onClick={() => scrollToSection('story')}
                        href="javascript:void(0);">
                        Our Story
                      </a>
                    </li>
                    <li>
                      <a
                        onClick={() => scrollToSection('friends-and-family')}
                        href="javascript:void(0);">
                        Friends & Family
                      </a>
                    </li>
                    <li>
                      <a
                        onClick={() => scrollToSection('gallery')}
                        href="javascript:void(0);">
                        Gallery
                      </a>
                    </li>
                    <li>
                      <a onClick={() => scrollToSection('rsvp')} to="#rsvp">
                        RSVP
                      </a>
                    </li>

                    <li>
                      <a
                        onClick={() => scrollToSection('event')}
                        href="javascript:void(0);">
                        Event
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </header>
  );
};

export default Header;
