import 'react-toastify/dist/ReactToastify.css';
import React from 'react';
import AllRoute from '../router';
import { ToastContainer } from 'react-toastify';
import '../../sass/style.scss';
import { ApolloProvider } from '@apollo/client';
import client from '../../utils/client';

const App = () => {
  return (
    <ApolloProvider client={client}>
      <div className="App" id="scrool">
        <AllRoute />
        <ToastContainer />
      </div>
    </ApolloProvider>
  );
};

export default App;
