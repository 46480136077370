function getFlashProducts(products) {
  return products.filter((item) => item.sale === true).slice(0, 8);
}

function getFeaturedProducts(products) {
  return products.filter((item) => item.sale === true).slice(0, 12);
}

function totalPrice(items) {
  return items.reduce((itemAcc, item) => {
    return (itemAcc += item.price * item.qty);
  }, 0);
}

function isWishListed(productId, wishList) {
  return wishList.findIndex((product) => product.id === productId) !== -1;
}

function getCompareList(items) {
  return items.slice(0, 4);
}

function searchFilter(row, search) {
  return row.title.toLowerCase().includes(search.toLowerCase()) || !search;
}

function formatDateLocale(date) {
  let dateString;
  // Todo: check if date is valid
  try {
    dateString = new Date(date)
      .toLocaleDateString('en-US', {
        weekday: 'short',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      })
      .split(', ')
      .slice(-2)
      .join(', ');
  } catch (error) {
    dateString = '';
  }

  return dateString;
}

function formatTimeLocale(date) {
  let timeString;
  try {
    var dt = date ? new Date(date) : new Date();
    var hours = dt.getHours();
    var AmOrPm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12 || 12;
    var minutes =
      dt.getMinutes() < 10 ? `0${dt.getMinutes()}` : dt.getMinutes();
    timeString = hours + ':' + minutes + ' ' + AmOrPm;
  } catch (error) {
    timeString = '';
  }
  return timeString;
}

// short helper function
function checkLengNull(data) {
  if (data !== null) {
    return data?.length > 0;
  }
  return false;
}

function isEquals(a, b) {
  if (a !== null && b !== null) {
    return a.toLowerCase() === b.toLowerCase();
  }
  return a === b;
}

function minValueOne(qty) {
  if (qty < 1) {
    return 1;
  }
  return qty;
}

// filter function
function filterProductByCategory(product, selected_category) {
  if (checkLengNull(selected_category)) {
    return product.category.toLowerCase() === selected_category.toLowerCase();
  }
  return true;
}

function filterProductByPrice(product, price) {
  if (checkLengNull(price)) {
    return product.price >= price[0] && product.price <= price[1];
  }
  return true;
}

function filterProductByColor(product, color) {
  if (checkLengNull(color)) {
    for (var i = 0; i < product.colors.length; i++) {
      if (product.colors[i].toLowerCase() === color.toLowerCase()) {
        return true;
      }
    }
    return false;
  }
  return true;
}

function filterProductBySize(product, size) {
  if (checkLengNull(size)) {
    for (var i = 0; i < product.size.length; i++) {
      if (product.size[i].toLowerCase() === size.toLowerCase()) {
        return true;
      }
    }
    return false;
  }
  return true;
}

function buildImageUrl(id) {
  return `${process.env.REACT_APP_BACKEND_IMAGE_URL}/assets/${id}?access_token=${process.env.REACT_APP_DIRECTUS_TOKEN}`;
}

export {
  getFlashProducts,
  getFeaturedProducts,
  totalPrice,
  isWishListed,
  filterProductByCategory,
  filterProductByPrice,
  filterProductByColor,
  filterProductBySize,
  isEquals,
  minValueOne,
  getCompareList,
  searchFilter,
  buildImageUrl,
  formatDateLocale,
  formatTimeLocale,
};
