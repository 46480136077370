import React, { useEffect, useRef } from 'react';

const BackgroundMusic = () => {
  const audioRef = useRef(null);

  useEffect(() => {
    const audio = audioRef.current;
    console.log('play audio');
    // Try to play the music when the component mounts
    const playAudio = () => {
      audio.play().catch((e) => {
        console.log('Auto-play failed, user interaction is required:', e);
      });
    };

    document.addEventListener('click', playAudio, { once: true });

    return () => {
      audio.pause();
      document.removeEventListener('click', playAudio);
    };
  }, []);

  return (
    <div style={{ display: 'none' }}>
      <audio ref={audioRef} loop>
        <source src={require('../../assets/perfect.mp3')} type="audio/mpeg" />
        Your browser does not support the audio element.
      </audio>
    </div>
  );
};

export default BackgroundMusic;
