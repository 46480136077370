import React, { Fragment } from 'react';

import { Button, Dialog, Grid } from '@mui/material';
import MuiDialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';

const LocationMap = ({ maxWidth, button, buttonClass, iframe }) => {
  const [open, setOpen] = React.useState(false);

  function handleClickOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  const DialogTitle = (props) => {
    const { onClose } = props;
    return (
      <MuiDialogTitle>
        {onClose ? (
          <IconButton
            className="event-close-btn"
            aria-label="close"
            onClick={onClose}>
            <i className="ti-close"></i>
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  };

  return (
    <Fragment>
      <Button className={`btn ${buttonClass}`} onClick={handleClickOpen}>
        {button}
        See Location
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        className="modalWrapper quickview-dialog"
        maxWidth={maxWidth}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          {' '}
        </DialogTitle>
        <Grid className="modalBody modal-body">
          <Grid className="modalBody modal-body">
            <iframe src={iframe}></iframe>
          </Grid>
        </Grid>
      </Dialog>
    </Fragment>
  );
};
export default LocationMap;
