import React from 'react';
import { Link } from 'react-router-dom';
import erimg from '../../images/error-404.svg';

const Error = ({ error }) => {
  const ClickHandler = () => {
    const reload = () => {
      window.location.reload();
    };
    reload();
  };

  console.log(error, '=====');

  return (
    <section className="error-404-section section-padding">
      <div className="container">
        <div className="row">
          <div className="col col-xs-12">
            <div className="content clearfix">
              <div className="error">
                <img src={erimg} alt="" />
              </div>
              <div className="error-message">
                <h3>Oops! An error occurred!</h3>
                <p>
                  We’re sorry but we seem to be facing issues trying to fetch
                  data, please try again later or contact the site
                  administrator.
                </p>
                <Link onClick={ClickHandler} to="" className="theme-btn">
                  {' '}
                  Reload
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Error;
