import React, { useState } from 'react';
import ListItem from '@mui/material/ListItem';
import './style.css';

const menus = [
  {
    id: 1,
    title: 'Home',
    link: '/#home',
  },
  {
    id: 2,
    title: 'Couple',
    link: '/#couple',
  },
  {
    id: 3,
    title: 'Our Story',
    link: '/#story',
  },
  {
    id: 3,
    title: 'Friends & Family',
    link: '/#friends-and-family',
  },
  {
    id: 4,
    title: 'RSVP',
    link: '/#rsvp',
  },
  {
    id: 5,
    title: 'Gallery',
    link: '/#gallery',
  },
  {
    id: 7,
    title: 'Event',
    link: '/#event',
  },
];

const MobileMenu = () => {
  const [menuActive, setMenuState] = useState(false);

  const scrollToSection = (sectionId) => {
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div>
      <div className={`mobileMenu ${menuActive ? 'show' : ''}`}>
        <div className="menu-close">
          <div className="clox" onClick={() => setMenuState(!menuActive)}>
            <i className="ti-close"></i>
          </div>
        </div>

        <ul className="responsivemenu">
          {menus.map((item, mn) => {
            return (
              <ListItem key={mn}>
                <a
                  onClick={() => scrollToSection(item.link.replace('/#', ''))}
                  href="javascript:void(0);">
                  {item.title}
                </a>
              </ListItem>
            );
          })}
        </ul>
      </div>

      <div className="showmenu" onClick={() => setMenuState(!menuActive)}>
        <button type="button" className="navbar-toggler open-btn">
          <span className="icon-bar first-angle"></span>
          <span className="icon-bar middle-angle"></span>
          <span className="icon-bar last-angle"></span>
        </button>
      </div>
    </div>
  );
};

export default MobileMenu;
