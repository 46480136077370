import React from 'react';
import { Slide } from 'react-awesome-reveal';
import { Link } from 'react-router-dom';
import shape1 from '../../images/footer-shape-1.svg';
import shape2 from '../../images/footer-shape-2.svg';

const scrollToSection = (sectionId) => {
  const element = document.getElementById(sectionId);
  if (element) {
    element.scrollIntoView({ behavior: 'smooth' });
  }
};

const Footer = (props) => {
  if (!props.data || !props.data.site_data?.length) {
    return '';
  }
  const site_data = props.data.site_data[0];

  return (
    <footer className={`wpo-site-footer ${props.footerClass}`}>
      <div className="wpo-upper-footer">
        <div className="container">
          <div className="row align-items-center">
            <div className="col col-xl-3  col-lg-4 col-md-6 col-sm-12 col-12">
              <div className="widget link-widget">
                <div className="widget-title">
                  <h3>Links</h3>
                </div>
                <div className="link-wrap">
                  <ul>
                    <li>
                      <a
                        onClick={() => scrollToSection('couple')}
                        href="javascript:void(0);">
                        Couple
                      </a>
                    </li>
                    <li>
                      <a
                        onClick={() => scrollToSection('story')}
                        href="javascript:void(0);">
                        Story
                      </a>
                    </li>
                    <li>
                      <a
                        onClick={() => scrollToSection('event')}
                        href="javascript:void(0);">
                        Event
                      </a>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <a
                        onClick={() => scrollToSection('gallery')}
                        href="javascript:void(0);">
                        Gallery
                      </a>
                    </li>
                    <li>
                      <a
                        onClick={() => scrollToSection('friends-and-family')}
                        href="javascript:void(0);">
                        Wedding Train
                      </a>
                    </li>
                    <li>
                      <a href="mailto:ndelucien008@gmail.com">Contact</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col col-xl-6 col-lg-4 col-md-6 col-sm-12 col-12">
              <div className="widget about-widget">
                <div className="logo widget-title">
                  <a
                    className="logo"
                    onClick={() => scrollToSection('home')}
                    href="javascript:void(0);">
                    <h3>{site_data?.title}</h3>
                  </a>
                </div>
                <p>
                  We can’t wait to see all of our beloved friends and relatives
                  at our wedding.
                </p>
                <ul>
                  <li>
                    <a
                      onClick={() => scrollToSection('home')}
                      href="javascript:void(0);">
                      <i className="ti-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={() => scrollToSection('home')}
                      href="javascript:void(0);">
                      <i className="ti-twitter-alt"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={() => scrollToSection('home')}
                      href="javascript:void(0);">
                      <i className="ti-instagram"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col col-xl-3  col-lg-4 col-md-6 col-sm-12 col-12">
              <div className="widget wpo-service-link-widget">
                <div className="widget-title">
                  <h3>Contact </h3>
                </div>
                <div className="contact-ft">
                  <p>ndelucien008@gmail.com</p>
                  <p>Molyko, Buea, Cameroon</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="wpo-lower-footer">
        <div className="container">
          <div className="row">
            <div className="col col-xs-12">
              <p className="copyright">
                {' '}
                &copy; Copyright 2024 |{' '}
                <Link
                  onClick={() =>
                    window.open('https://ndelucien.netlify.app/', '_blank')
                  }
                  to="https://ndelucien.netlify.app">
                  DarkMatter Technologies
                </Link>{' '}
                | All right reserved.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="ft-shape-1">
        <Slide direction="left" duration="1000" triggerOnce="true">
          <img src={shape1} alt="" />
        </Slide>
      </div>
      <div className="ft-shape-2">
        <Slide direction="right" duration="1200" triggerOnce="true">
          <img src={shape2} alt="" />
        </Slide>
      </div>
    </footer>
  );
};

export default Footer;
