import React, { useState } from 'react';
import SimpleReactValidator from 'simple-react-validator';
import { CREATE_INVITATION_ITEM, UPDATE_INVITATION_ITEM } from '../../data';
import Loading from '../loader/Loader';
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';

const RSVPFrom = ({ data, validId }) => {
  const [dataLoad, setDataLoad] = useState(data);
  const [forms, setForms] = useState({
    name: validId
      ? dataLoad?.name
        ? dataLoad?.name
        : `${dataLoad?.first_name} ${dataLoad?.last_name}`
      : '',
    phone: validId ? (dataLoad?.phone ? dataLoad?.phone : '') : '',
    rsvp: '',
    plusOne: '',
  });
  const [updateInvitationItem, { data: updatedData, loading, error }] =
    useMutation(UPDATE_INVITATION_ITEM);

  const [
    createInvitationItem,
    { data: createData, loading: createLoading, error: createError },
  ] = useMutation(CREATE_INVITATION_ITEM);

  const [validator] = useState(
    new SimpleReactValidator({
      className: 'errorMessage',
    })
  );

  const changeHandler = (e) => {
    setForms({ ...forms, [e.target.name]: e.target.value });
    if (validator.allValid()) {
      validator.hideMessages();
    } else {
      validator.showMessages();
    }
  };
  // if (!data) {
  //   return '';
  // }

  const submitHandler = async (e) => {
    e.preventDefault();
    if (validator.allValid()) {
      console.log(forms);
      validator.hideMessages();
      setForms({
        name: '',
        phone: '',
        rsvp: '',
        plusOne: '',
      });

      if (validId) {
        const result = await updateInvitationItem({
          variables: {
            id: data?.id,
            data: {
              rsvp: forms.rsvp === 'yes',
              plus_one: forms.plusOne === 'yes',
              phone: forms.phone,
              name: forms.name,
              accepted_at: new Date(),
            },
          },
        });
        setDataLoad(result.data.update_invitation_item);
        toast.success('You have successfully submitted your RSVP!');
      } else {
        const result = await createInvitationItem({
          variables: {
            data: {
              rsvp: forms.rsvp === 'yes',
              plus_one: forms.plusOne === 'yes',
              phone: forms.phone,
              name: forms.name,
              accepted_at: new Date(),
            },
          },
        });
        console.log(result);
        setDataLoad(result.data.create_invitation_item);
        toast.success('You have successfully submitted your RSVP!');
      }
    } else {
      validator.showMessages();
    }
  };

  if (dataLoad?.accepted_at) {
    return (
      <div className="text-center">
        <h1>
          Thank you{' '}
          {dataLoad?.name
            ? dataLoad?.name
            : `${dataLoad?.first_name} ${dataLoad?.last_name}`}
        </h1>
        <h3>We have received your response to the invitation</h3>
      </div>
    );
  }

  return (
    <form
      onSubmit={(e) => submitHandler(e)}
      className="contact-validation-active">
      {(loading || createLoading) && <Loading />}
      <div className="row">
        <div className="col col-lg-12 col-12">
          <div className="form-field">
            <input
              value={forms.name}
              type="text"
              name="name"
              onBlur={(e) => changeHandler(e)}
              onChange={(e) => changeHandler(e)}
              className="form-control"
              placeholder="Your Name"
            />
            {validator.message('name', forms.name, 'required|alpha_space')}
          </div>
        </div>
        <div className="col col-lg-12 col-12">
          <div className="form-field">
            <input
              value={forms.phone}
              type="phone"
              name="phone"
              onBlur={(e) => changeHandler(e)}
              onChange={(e) => changeHandler(e)}
              className="form-control"
              placeholder="Your phone"
            />
            {validator.message('phone', forms.phone, 'required|phone')}
          </div>
        </div>
        <div className="col col-lg-12 col-12">
          <h4>Will you attend?</h4>
          <div className="radio-buttons">
            <p>
              <input
                type="radio"
                id="attend"
                name="rsvp"
                value={'yes'}
                onChange={(e) => changeHandler(e)}
              />
              <label htmlFor="attend">Yes, I will be there</label>
            </p>
            <p>
              <input
                type="radio"
                id="not"
                name="rsvp"
                value={'no'}
                onChange={(e) => changeHandler(e)}
              />
              <label htmlFor="not">Sorry, I can’t come</label>
            </p>
          </div>
          {validator.message('rsvp', forms.rsvp, 'required')}
        </div>
        {forms.rsvp === 'yes' && (
          <div className="col col-lg-12 col-12">
            <div className="form-field">
              <h4>Will you have a plus one?</h4>
              <div className="radio-buttons">
                <p>
                  <input
                    type="radio"
                    id="plusOneYes"
                    name="plusOne"
                    value="yes"
                    onChange={(e) => changeHandler(e)}
                  />
                  <label htmlFor="plusOneYes">Yes</label>
                </p>
                <p>
                  <input
                    type="radio"
                    id="plusOneNo"
                    name="plusOne"
                    value="no"
                    onChange={(e) => changeHandler(e)}
                  />
                  <label htmlFor="plusOneNo">No</label>
                </p>
              </div>
              {validator.message('plusOne', forms.plusOne, 'required')}
            </div>
          </div>
        )}
      </div>
      <div className="submit-area">
        <button type="submit" className="theme-btn">
          RSVP
        </button>
      </div>
    </form>
  );
};

export default RSVPFrom;
