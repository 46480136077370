import React from 'react';
import TimeCountDown from '../countdown';
import sImg1 from '../../images/wedding-date/1.png';

const WeddingCountdown = ({ date }) => {
  return (
    <section className="wpo-wedding-date">
      <div className="row">
        <div className="col col-xs-12">
          <div className="clock-grids">
            <div id="clock">
              <TimeCountDown date={date} />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WeddingCountdown;
