// queries.js

import { gql } from '@apollo/client';

export const GET_IMAGES = gql`
  query {
    images {
      id
      him_260 {
        id
      }
      her_260 {
        id
      }
      they_461_717 {
        id
      }
      him_515_468 {
        id
      }
      her_515_468 {
        id
      }
    }
  }
`;

export const GET_SITE_DATA = gql`
  query {
    site_data {
      id
      title
      wedding_date
      her_name
      his_name
      about_her
      about_him
    }
  }
`;

export const GET_STORIES = gql`
  query {
    stories {
      id
      story_1_title
      story_2_title
      story_3_title
      story_1_content
      story_2_content
      story_3_content
      story_1_date
      story_2_date
      story_3_date
      story_1_image {
        id
      }
      story_2_image {
        id
      }
      story_3_image {
        id
      }
    }
  }
`;

export const GET_SOCIALS = gql`
  query {
    socials {
      id
      her_facebook
      her_twitter
      her_instagram
      his_facebook
      his_twitter
      his_instagram
    }
  }
`;

export const GET_WEDDING_TRAIN = gql`
  query {
    wedding_train {
      id
      name
      image {
        id
      }
      title
    }
  }
`;

export const GET_GALLERY = gql`
  query {
    gallery {
      id
      image {
        id
      }
    }
  }
`;

export const GET_CEREMONIES = gql`
  query {
    ceremonies {
      id
      name
      datetime
      location
      image {
        id
      }
      iframe
    }
  }
`;

export const GET_INVITATION_BY_ID = gql`
  query GetInvitationById($id: ID!) {
    invitation_by_id(id: $id) {
      id
      name
      email
      phone
      rsvp
      plus_one
      accepted_at
    }
  }
`;

export const UPDATE_INVITATION_ITEM = gql`
  mutation UpdateInvitationItem($id: ID!, $data: update_invitation_input!) {
    update_invitation_item(id: $id, data: $data) {
      id
      name
      email
      phone
      rsvp
      plus_one
      accepted_at
    }
  }
`;

export const CREATE_INVITATION_ITEM = gql`
  mutation CreateInvitationItem($data: create_invitation_input!) {
    create_invitation_item(data: $data) {
      name
      email
      rsvp
      plus_one
      accepted_at
    }
  }
`;

export const GET_GIFTS = gql`
  query {
    ceremonies {
      id
      name
      image {
        id
      }
      link
    }
  }
`;
