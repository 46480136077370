import React, { Fragment, useState, useEffect } from 'react';
import Navbar from '../../components/Navbar/Navbar';
import Hero from '../../components/hero/Hero';
import CoupleSection from '../../components/CoupleSection2/CoupleSection2';
import StorySection from '../../components/StorySection2/StorySection2';
import PortfolioSection from '../../components/PortfolioSection';
import RsvpSection from '../../components/RsvpSection/RsvpSection';
import EventSection from '../../components/EventSection/EventSection';
import Footer from '../../components/footer/Footer';
import Scrollbar from '../../components/scrollbar/scrollbar';
import PortfolioSectionS2 from '../../components/PortfolioSectionS2/PortfolioSectionS2';
import {
  GET_GALLERY,
  GET_IMAGES,
  GET_SITE_DATA,
  GET_SOCIALS,
  GET_STORIES,
  GET_WEDDING_TRAIN,
  GET_CEREMONIES,
  GET_INVITATION_BY_ID,
} from '../../data';
import { useQuery } from '@apollo/client';
import BrideGrooms from '../../components/BrideGrooms';
import Loader from '../../components/loader/Loader';
import Error from '../../components/404/404';
import { useLocation } from 'react-router-dom';
import { validate as uuidValidate } from 'uuid';
import BackgroundMusic from '../../components/BackgroundMusic/BackgroundMusic';

const HomePage = () => {
  const [isValidInviteId, setIsValidInviteId] = useState(false);
  const [inviteId, setInviteId] = useState('');
  const location = useLocation();
  const query = new URLSearchParams(location.search);

  useEffect(() => {
    const inviteId = query.get('invite-id');
    if (inviteId && uuidValidate(inviteId)) {
      setInviteId(inviteId);
      setIsValidInviteId(true);
    } else {
      setInviteId('');
      setIsValidInviteId(false);
    }
  }, []);

  const image_data = useQuery(GET_IMAGES);
  const site_data = useQuery(GET_SITE_DATA);
  const stories_data = useQuery(GET_STORIES);
  const socials_data = useQuery(GET_SOCIALS);
  const train_data = useQuery(GET_WEDDING_TRAIN);
  const gallery_data = useQuery(GET_GALLERY);
  const ceremony_data = useQuery(GET_CEREMONIES);
  const invitation_data = useQuery(GET_INVITATION_BY_ID, {
    variables: { id: inviteId },
    skip: !inviteId,
  });

  if (
    image_data.loading ||
    site_data.loading ||
    stories_data.loading ||
    socials_data.loading ||
    train_data.loading ||
    gallery_data.loading ||
    ceremony_data.loading ||
    invitation_data.loading
  ) {
    return <Loader />;
  }

  if (
    image_data.error ||
    site_data.error ||
    stories_data.error ||
    socials_data.error ||
    train_data.error ||
    gallery_data.error ||
    ceremony_data.error ||
    invitation_data.error
  ) {
    return (
      <Error
        error={
          image_data.error ||
          site_data.error ||
          stories_data.error ||
          socials_data.error ||
          train_data.error ||
          gallery_data.error ||
          ceremony_data.error ||
          invitation_data.error
        }
      />
    );
  }

  return (
    <Fragment>
      <Navbar />
      <Hero
        data={{
          images: image_data.data.images,
          site_data: site_data.data.site_data,
        }}
      />
      <CoupleSection
        data={{
          images: image_data.data.images,
          site_data: site_data.data.site_data,
          socials: socials_data.data.socials,
        }}
      />
      <StorySection
        data={{
          stories: stories_data.data.stories,
        }}
      />
      <BrideGrooms
        data={{
          wedding_train: train_data.data.wedding_train,
        }}
      />
      {/* <PortfolioSection
        data={{
          gallery: gallery_data.data.gallery,
        }}
      /> */}
      <PortfolioSectionS2
        data={{
          gallery: gallery_data.data.gallery,
        }}
      />
      <RsvpSection
        data={{
          validId: isValidInviteId,
          invitation_data: invitation_data?.data?.invitation_by_id,
        }}
      />
      <EventSection
        data={{
          ceremonies: ceremony_data.data?.ceremonies,
        }}
      />
      <BackgroundMusic />

      <Footer
        data={{
          site_data: site_data.data.site_data,
        }}
      />
      <Scrollbar />
    </Fragment>
  );
};

export default HomePage;
