import React, { useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import './ReadMore.css';

const ReadMore = ({ children = '', characterLimit = 100 }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleReadMore = () => {
    setIsExpanded(!isExpanded);
  };

  const getDisplayText = () => {
    if (!children) {
      return '';
    }
    if (isExpanded) {
      return children;
    }

    return (
      children?.substring(0, characterLimit) +
      (children?.length > characterLimit ? '...' : '')
    );
  };

  return (
    <div className="read-more-container">
      <CSSTransition
        in={isExpanded}
        timeout={300}
        classNames="slide"
        mountonexit={isExpanded ? 'true' : 'false'}>
        <div className="read-more-content">{getDisplayText()}</div>
      </CSSTransition>
      <span onClick={toggleReadMore} className="read-more-button">
        {children?.length > characterLimit
          ? isExpanded
            ? 'Read Less'
            : 'Read More'
          : ''}
      </span>
    </div>
  );
};

export default ReadMore;
