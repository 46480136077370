import React from 'react';
import { Zoom } from 'react-awesome-reveal';
import SectionTitle from '../SectionTitle';

import shape1 from '../../images/story/shape.png';
import shape2 from '../../images/story/shape2.png';
import shape3 from '../../images/story/shape3.png';

import bshape1 from '../../images/story/flower-shape1.svg';
import bshape2 from '../../images/story/flower-shape2.svg';
import bshape3 from '../../images/story/flower-shape3.svg';
import bshape4 from '../../images/story/flower-shape4.svg';
import { buildImageUrl, formatDateLocale } from '../../utils';
import ReadMore from '../ReadMore/ReadMore';

const StorySection2 = ({ data }) => {
  if (!data) {
    return '';
  }

  const stories = data.stories[0];

  const Stories = [
    {
      sImg: buildImageUrl(stories?.story_1_image?.id),
      shape: shape1,
      title: stories?.story_1_title,
      date: stories?.story_1_date
        ? formatDateLocale(stories?.story_1_date)
        : '',
      description: stories?.story_1_content,
    },
    {
      sImg: buildImageUrl(stories?.story_2_image?.id),
      shape: shape2,
      title: stories?.story_2_title,
      date: stories?.story_2_date
        ? formatDateLocale(stories?.story_2_date)
        : '',
      description: stories?.story_2_content,
      order1: 'order-lg-2 order-1',
      order2: 'order-lg-1 order-2',
    },
    {
      sImg: buildImageUrl(stories?.story_3_image?.id),
      shape: shape3,
      title: stories?.story_3_title,
      date: stories?.story_3_date
        ? formatDateLocale(stories?.story_3_date)
        : '',
      description: stories?.story_3_content,
    },
  ];
  return (
    <section className="wpo-story-section section-padding" id="story">
      <div className="container">
        <SectionTitle subTitle={'Our Story'} MainTitle={'How it Happened'} />
        <div className="wpo-story-wrap">
          {Stories.map((story, st) => (
            <div className="wpo-story-item" key={st}>
              <div className="wpo-story-img-wrap">
                <Zoom direction="up" duration="1000" triggerOnce="true">
                  <div className="wpo-story-img">
                    <img src={story.sImg} alt="" />
                  </div>
                </Zoom>
                <div className="clip-shape">
                  <svg
                    viewBox="0 0 382 440"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path d="M191 0L381.526 110V330L191 440L0.474411 330V110L191 0Z" />
                  </svg>
                </div>
                <div className="wpo-img-shape">
                  <img src={story.shape} alt="" />
                </div>
              </div>
              <div className="wpo-story-content">
                <div className="wpo-story-content-inner">
                  <span>{story.date}</span>
                  <h2>{story.title}</h2>
                  <ReadMore characterLimit={180}>{story.description}</ReadMore>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="flower-shape-1">
        <img src={bshape1} alt="" />
      </div>
      <div className="flower-shape-2">
        <img src={bshape2} alt="" />
      </div>
      <div className="flower-shape-3">
        <img src={bshape3} alt="" />
      </div>
      <div className="flower-shape-4">
        <img src={bshape4} alt="" />
      </div>
    </section>
  );
};

export default StorySection2;
